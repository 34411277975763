
/**
 * @name: 商品分类
 * @author: itmobai
 * @date: 2023-05-31 14:22
 * @description：商品分类
 * @update: 2023-05-31 14:22
 */
import {Component, Vue} from "vue-property-decorator";
import {ICrudOption} from "@/types/m-ui-crud";
import config from "@/config";
import {
  productsortCreateApi,
  productsortModifyApi,
  productsortQueryByListApi,
  productsortQueryProductSortListApi,
  productsortRemoveApi,
  productsortStatusUpdateApi
} from "@/apis/product/classification";
import {ISort, ISortParam} from "@/apis/product/classification/types";
import { checkPositiveInteger } from "@/constants/validators"

@Component({})
export default class ClassificationPage extends Vue {
  config = config
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格总数
  tableTotal: number = 0;
  // 表格数据
  tableData: ISort[] = []
  // 表单
  modelForm: Partial<ISort> = {}
  // 查询参数
  queryParam: ISortParam = {
    id: "",
    sortNameKeywords: ""
  }
  // crudOption
  option: ICrudOption = {
    labelWidth: '120px',
    addTitle: '新增商品分类',
    editTitle: '编辑商品分类',
    dialogWidth: '600px',
    column: [
      {
        "label": "ID",
        "prop": "idStr",
        "search": true,
        "addHide": true,
        "editHide": true,
        "hide": true
      },
      {
        "label": "ID",
        "prop": "id",
        "align": "left",
        "width": "200",
        "addHide": true,
        "editHide": true
      },
      {
        "label": "上级分类",
        "prop": "pid",
        "hide": true,
        "type": "select"
      },
      {
        "label": "分类名称",
        "prop": "sortNameKeywords",
        "hide": true,
        "addHide": true,
        "editHide": true,
        "viewHide": true,
        "search": true
      },
      {
        "label": "分类名称",
        "prop": "sortName",
        "align": "center",
        "overHidden": true,
        maxlength: 20,
        "rules": [
          {required: true, message: '请输入分类名称', trigger: 'blur'}
        ]
      },
      {
        "label": "分类图标",
        "prop": "sortIcon",
        "overHidden": false,
        "align": "center",
        "type": "image",
        "imgPrefix": config.downloadUrl,
        "imgWidth": '50px',
        "imgHeight": '50px',
        "addSlot": true,
        "editSlot": true
      },
      {
        "label": "排序",
        "prop": "sort",
        "align": "center",
        "type": "number",
        maxlength: 4,
        "rules": [
          {validator: checkPositiveInteger, trigger: 'blur'}
        ]
      },
      {
        "label": "状态",
        "prop": "status",
        "align": "center",
        "addHide": true,
        "editHide": true,
        "slot": true
      }
    ]
  }

  rowDel (form: ISort, index: number) {
    productsortRemoveApi(form.id).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    })
  }

  rowSave (form: ISort, done: Function, loading: Function) {
    productsortCreateApi(form).then(e => {
      if (e) {
        done()
        this.$message.success("操作成功!")
        this.getList()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: ISort, done: Function, loading: Function) {
    productsortModifyApi({
      id: form.id,
      pid: form.pid,
      sort: form.sort,
      sortName: form.sortName,
      sortIcon: form.sortIcon
    }).then(e => {
      if (e) {
        done()
        this.$message.success("操作成功!")
        this.getList()
      }
    }).finally(() => {
      loading()
    })
  }

  getPidSelect () {
    return new Promise(resolve => {
      productsortQueryProductSortListApi().then(e => {
        // @ts-ignore
        this.$refs.crudRef.updateFormColumn('pid', {
          dicData: e.map(item => {
            return {
              label: item.sortName,
              value: item.id
            }
          })
        })
        resolve(null)
      })
    })
  }

  openAdd () {
    // @ts-ignore
    this.$refs.crudRef.rowAdd();
    this.getPidSelect()
  }

  openEdit (row: ISort, index: number) {
    // @ts-ignore
    this.$refs.crudRef.rowEdit(row, index);
    this.getPidSelect()
  }

  handleSwitch (id: string, status: number) {
    this.tableLoading = true
    productsortStatusUpdateApi(id, status).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    }).finally(() => {
      this.tableLoading = false
    })
  }

  beforeUpload (file: File) {
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      this.$message.error('上传图标大小不能超过5MB!');
    }
    return isLt2M;
  }

  getList () {
    this.tableLoading = true
    productsortQueryByListApi(this.queryParam).then(e => {
      this.tableLoading = false
      this.tableData = e;
    })
  }

  created () {
    this.getList()
  }
}
